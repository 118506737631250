import React, { Component } from 'react';

import '../scss/web/contacto.scss';

import icon_ubicacion from '../img/contacto/ubicacion.png';
import icon_telefono from '../img/contacto/telefono.png';
import icon_mail from '../img/contacto/mail.png';
import lugar from '../img/contacto/lugar.jpg';

import FormContacto from './components/FormContacto';
import Mapa from './components/Mapa';

class Contacto extends Component {
    render () {
        return (
            <section className="contacto">
                <div className="contacto-form">
                    <div className="container position-relative">
                        <div className="row">
                            <div className="col-12 col-md-6 m50 mb-md-0">
                                <h3 className="titulo-amarillo-negro text-uppercase text-center m30">Contáctanos</h3>
                                <div className="row align-items-center">
                                    <div className="col-12 col-lg-10">
                                        <p><img className="mr-3" src={icon_ubicacion} alt="Direccion"/>Avenida Yucatán #622 Col. Jardines de Mérida</p>
                                        <p><img className="mr-3" src={icon_telefono} alt="Telefono"/>(999) 481 9467</p>
                                        <p className="m30"><img className="mr-3" src={icon_mail} alt="Mail"/>ventas@graphics.digital</p>

                                        <FormContacto />
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-6 text-center deco">
                                <div className="bg h-100" style={{backgroundImage: `url(${lugar})`}}>
                                    <img className="w-max-100" src={lugar} alt="Establecimiento"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="contacto-mapa">
                    <div className="container">
                        <Mapa />
                    </div>
                </div>
            </section>
        );
    }
}

export default Contacto;
import React, { Component } from 'react';

import '../scss/web/nosotros.scss';

import banner from '../img/nosotros/banner.jpg';
import valores_icon_1 from '../img/nosotros/icon-calidad.svg';
import valores_icon_2 from '../img/nosotros/icon-compromiso.svg';
import valores_icon_3 from '../img/nosotros/icon-servicio.svg';
import valores_icon_4 from '../img/nosotros/icon-honestidad.svg';
import valores_icon_5 from '../img/nosotros/icon-respeto.svg';
import valores_icon_6 from '../img/nosotros/icon-integridad.svg';

class Nosotros extends Component {
    render () {
        return (
            <section className="nosotros">
                {/* Banner */}
                <div className="nosotros-banner">
                    <div className="container-fluid w14 w-md-98">
                        <img src={banner} className="w-max-100" alt="Nosotros banner"></img>
                    </div>
                </div>

                {/* Informacion */}
                <div className="nosotros-informacion">
                    <div className="container-fluid w14">
                        <div className="row justify-content-center">
                            <div className="col-12 col-md-10 col-lg-8 text-center">
                                <h3 className="titulo-amarillo-negro text-uppercase m40">Nuestra empresa</h3>
                                <p className="mb-0">Nuestros sistemas de impresión de digital, offset, y gran formato, puedes imprimir: tarjetas, volantes, folletos, dípticos, trípticos, etiquetas, empaques, posters, menús, invitaciones, sobres, hojas membretadas, libros, Catálogos, tend cards, material POP, canvas, papel tapiz, lona, vinil, tela, entre otros.</p>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Valores */}
                <div className="nosotros-valores">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-12 col-lg-3 text-center text-lg-left">
                                <h4 className="titulo-blanco-negro small-titulo text-uppercase m30">Valores</h4>
                            </div>
                            <div className="col-12 col-lg-9">
                                <div className="row align-items-center text-center text-lg-left">
                                    <div className="col-12 col-md-4 m40 m-lg-50">
                                        <img src={valores_icon_1} className="mr-3" alt="Calidad"></img>
                                        <span className="text text-uppercase">Calidad</span>
                                    </div>
                                    <div className="col-12 col-md-4 m40 m-lg-50">
                                        <img src={valores_icon_2} className="mr-3" alt="Compromiso"></img>
                                        <span className="text text-uppercase">Compromiso</span>
                                    </div>
                                    <div className="col-12 col-md-4 m40 m-lg-50">
                                        <img src={valores_icon_3} className="mr-3" alt="Servicio"></img>
                                        <span className="text text-uppercase">Servicio</span>
                                    </div>
                                    <div className="col-12 col-md-4 m40 m-lg-50">
                                        <img src={valores_icon_4} className="mr-3" alt="Honestidad"></img>
                                        <span className="text text-uppercase">Honestidad</span>
                                    </div>
                                    <div className="col-12 col-md-4 m40 m-lg-50">
                                        <img src={valores_icon_5} className="mr-3" alt="Respeto"></img>
                                        <span className="text text-uppercase">Respeto</span>
                                    </div>
                                    <div className="col-12 col-md-4 m40 m-lg-50">
                                        <img src={valores_icon_6} className="mr-3" alt="Integridad"></img>
                                        <span className="text text-uppercase">Integridad</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
};

export default Nosotros;
import React, { Component } from 'react';
import ReactFileReader from 'react-file-reader';

import icon_upload from '../../img/icon-upload.png';

class CustomFileInput extends Component {
    state = {
        file: ""
    }

    handleFiles = files => {
        this.setState({
            file: files.base64
        });
    }

    render () {
        return (
            <div className="files">
                <ReactFileReader handleFiles={this.handleFiles} base64={true}>
                    <button type="button" className='btn'>
                        <img src={icon_upload} alt="Base"/>
                    </button>
                </ReactFileReader>
                <div className="bg bg-size-contain" style={{backgroundImage: `url(${this.state.file})`}} />
            </div>
        );
    }
}

export default CustomFileInput;
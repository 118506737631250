import React, { Component } from 'react';
import ReCAPTCHA from "react-google-recaptcha";
import axios from 'axios';
import Swal from 'sweetalert2'

import CustomFileInput from './CustomFileInput';

class FormCotizacion extends Component {

    constructor(props) {
        super(props);

        this.state = {
            nombre: "",
            celular: "",
            email: "",
            mensaje: "",
            servicio: "",

            verificado: false,
            enviado: false
        }

        this.handleSubmit = this.handleSubmit.bind(this);
        this.verifyCallback = this.verifyCallback.bind(this);
    }

    handleSubmit(ev) {
        ev.preventDefault();
        let form = document.querySelector(".validate");

        if (form.checkValidity() === false) {
            //Campos invalidos
            ev.preventDefault();
            ev.stopPropagation();
        } else {
            //TODO: Verificar captcha
            //Hacemos submit jeje
            if(this.state.verificado){
                this.setState({...this.state, sending: true});

                let f1 = document.querySelector('.file_1 input[type="file"]').files[0];
                let f2 = document.querySelector('.file_2 input[type="file"]').files[0];

                const formData = new FormData();
                formData.append('nombre', this.state.nombre);
                formData.append('servicio', this.state.servicio);
                formData.append('email', this.state.email);
                formData.append('celular', this.state.celular);
                formData.append('mensaje', this.state.mensaje);
                formData.append('file_1', f1);
                formData.append('file_2', f2);

                const config = {
                    headers: {
                        'content-type': 'multipart/form-data'
                    }
                }

                axios.post(`${process.env.REACT_APP_API_URL}cotizacion`, formData, config)
                .then(res => {
                    console.log(res.status);
                    if((res.status === 200) && (res.data.success)){
                        // Mostramos mensaje enviado
                        Swal.fire(
                            'Operación exitosa',
                            'Mensaje enviado',
                            'success'
                        )
                    }else{
                        Swal.fire(
                            'Oops!',
                            'Lo sentimos, algo salió mal',
                            'error'
                        )
                    }
                    this.setState({...this.state, sending: false});
                })
                .catch(err => {
                    console.log(err);
                    Swal.fire(
                        'Oops!',
                        'Lo sentimos, algo salió mal',
                        'error'
                    )
                    this.setState({...this.state, sending: false});
                });
            } else{
                Swal.fire(
                    'Oops...',
                    'Favor de verificar el captcha',
                    'warning'
                )
            }
        }

        form.classList.add('was-validated');
    }

    verifyCallback (response) {
        this.setState({...this.state, verificado: true});
    }

    showButton(){
        if(this.state.sending){
            return <button type="submit" className="btn btn-blanco-magenta text-uppercase disabled" style={{PointerEvent: "none"}}>Enviando....</button>
        }else{
            return <button type="submit" onClick={this.handleSubmit} className="btn btn-blanco-magenta text-uppercase">Enviar</button>
        }
    }

    render () {
        return (
            <form className="validate" noValidate>
                <div className="row">
                    <div className="col-12 col-md-6">
                        <input placeholder="Nombre" className="form-control in m15" type="text" name="nombre" onChange={(el) => {this.setState({...this.state, nombre: el.target.value})}} required />
                        <select name="servicio" className="m15 form-control sl" onChange={(el) => {this.setState({...this.state, servicio: el.target.value})}} >
                            <option value="">Seleccione una opción</option>
                            <option value="Impresión digital">Impresión digital</option>
                            <option value="Impresión offset">Impresión offset</option>
                            <option value="Gran formato">Gran formato</option>
                        </select>
                        <input placeholder="E-mail" className="form-control in m15" type="email" name="email" onChange={(el) => {this.setState({...this.state, email: el.target.value})}} required />
                        <input placeholder="Celular" className="form-control in m15" type="text" name="celular" onChange={(el) => {this.setState({...this.state, celular: el.target.value})}} required />

                        <div className="row">
                            <div className="col-12 col-lg-6 m15 mb-lg-0 file_1">
                                <CustomFileInput />
                                <label className="mt-3 text-center d-block small">Adjuntar Archivo 1</label>
                            </div>
                            <div className="col-12 col-lg-6 file_2">
                                <CustomFileInput />
                                <label className="mt-3 text-center d-block small">Adjuntar Archivo 2</label>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-6">
                        <textarea className="form-control m15 in" rows="10" placeholder="Mensaje / Nota" onChange={(el) => {this.setState({...this.state, mensaje: el.target.value})}}></textarea>
                        <div className="text-center d-flex align-items-center justify-content-center m20">
                            <ReCAPTCHA
                                sitekey="6LdrSYIaAAAAABnZA5ttwo4r_XSOh_LJYKoZ8r1k"
                                onChange={this.verifyCallback}
                                size="normal"
                                badge="inline"
                            />
                        </div>
                        <div className="form-group m20">
                            <input className="custom-checkbox" type="checkbox" id="chkbx_2" value="1" required/>
                            <label htmlFor="chkbx_2">
                                <span></span> Acepto término y condiciones
                            </label>
                        </div>
                        <div className="position-relative" style={{zIndex: 1}}>
                            {this.showButton()}
                        </div>
                    </div>
                </div>
            </form>
        );
    }
}

export default FormCotizacion;
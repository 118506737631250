import React, { Component } from "react";
import { Link, NavLink } from "react-router-dom";

import logo from "../../img/logo-header.jpg";

class Header extends Component {
    constructor(props){
        super(props);
        this.toggle = this.toggle.bind(this);
        this.state = {
            open: false
        }
    }

    toggle() {
        this.setState({
            open: !this.state.open
        });

    }

    render() {
        return (
            <header>
                <div className="menu-escritorio d-none d-lg-block">
                    <div className="container-fluid w14">
                        <ul className="list-unstyled p-0 m-0 d-flex align-items-center justify-content-between">
                            <li>
                                <Link to="/">
                                    <img src={logo} className="logo w-max-100" alt="Graphics Digital"></img>
                                </Link>
                            </li>
                            <li></li>
                            <li className="text-uppercase"><NavLink exact activeClassName="active" to="/">Inicio</NavLink></li>
                            <li className="text-uppercase">
                                <span onClick={() => window.location.replace("/#servicios")} className="pointer">
                                    Servicios
                                </span>
                            </li>
                            <li className="text-uppercase"><NavLink exact activeClassName="active" to="/nosotros">Nosotros</NavLink></li>
                            <li className="text-uppercase"><NavLink exact activeClassName="active" to="/tecnologia-uv">Tecnología uv</NavLink></li>
                            <li className="text-uppercase"><NavLink exact activeClassName="active" to="/contacto">Contacto</NavLink></li>
                            {/* <li className="text-uppercase"><NavLink exact activeClassName="active" to="/cotizar">Cotizar</NavLink></li> */}
                        </ul>
                    </div>
                </div>

                <div className="menu-movil d-lg-none py-2">
                    <div className="container-fluid">
                        <div className="row align-items-center">
                            <div className="col-6">
                                <Link to="/">
                                    <img src={logo} className="logo w-max-100" alt="Graphics Digital" style={{maxWidth: "80px"}}></img>
                                </Link>
                            </div>
                            <div className="col-6 text-right">
                                <div className={`menu menu-3 ${(this.state.open) ? 'active' : ''}`} onClick={this.toggle}>
                                    <span></span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={`sidebar py-5 px-4 ${(this.state.open) ? 'active' : ''}`}>
                        <ul className="list-unstyled p-0 m-0">
                            <li className="text-uppercase mb-4" onClick={this.toggle}><NavLink exact activeClassName="active" to="/">Inicio</NavLink></li>
                            <li className="text-uppercase mb-4" onClick={this.toggle}><span onClick={() => window.location.replace("/#servicios")} className="pointer"> Servicios </span> </li>
                            <li className="text-uppercase mb-4" onClick={this.toggle}><NavLink exact activeClassName="active" to="/nosotros">Nosotros</NavLink></li>
                            <li className="text-uppercase mb-4" onClick={this.toggle}><NavLink exact activeClassName="active" to="/tecnologia-uv">Tecnología uv</NavLink></li>
                            <li className="text-uppercase mb-4" onClick={this.toggle}><NavLink exact activeClassName="active" to="/contacto">Contacto</NavLink></li>
                            <li className="text-uppercase mb-4" onClick={this.toggle}><NavLink exact activeClassName="active" to="/cotizar">Cotizar</NavLink></li>
                        </ul>
                    </div>
                </div>
            </header>
        );
    }
}

export default Header;
import React, { Component } from 'react';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';

import pin from '../../img/pin.png';

const containerStyle = {
    width: '100%',
    height: '400px'
};

const center = {
    lat: 21.002096,
    lng: -89.589434
};

class Mapa extends Component {
    render () {
        return (
            <LoadScript googleMapsApiKey="AIzaSyBuu1Q0hHmlO30h7YRDZ0mWYof-SM-edns">
                <GoogleMap
                    mapContainerStyle={containerStyle}
                    center={center}
                    zoom={14}
                >
                    <Marker position={center} icon={pin} />
                </GoogleMap>
            </LoadScript>
        );
    }
}

export default Mapa;
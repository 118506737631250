import React, { Component } from 'react';

import '../scss/web/tecnologia.scss';

import amenidades from '../img/tecnologia/amenidades.png';
import amenidades_movil from '../img/tecnologia/amenidades-movil.png';
import banner from '../img/tecnologia/banner.jpg';

class Tecnologia extends Component {
    render () {
        return (
            <section className="tecnologia">
                <div className="tecnologia-amenidades">
                    <div className="container-fluid w14 position-relative text-center">
                        <h3 className="titulo-negro-amarillo text-uppercase text-center m60 m-lg-90">tecnología de tintas UV</h3>
                        <br />
                        <img className="w-max-100 d-none d-md-inline-block" src={amenidades} alt="Amenidades"></img>
                        <img className="w-max-100 d-md-none" src={amenidades_movil} alt="Amenidades"></img>
                    </div>
                </div>

                <div className="tecnologia-baner">
                    <div className="lines"></div>
                    <div className="container-fluid w16 px-0 text-center">
                        <img src={banner} alt="Banner" className="w-max-100 img_banner"></img>
                    </div>
                </div>
            </section>
        );
    }
}

export default Tecnologia;
import React, { Component } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import axios from 'axios';
import Swal from 'sweetalert2'

class FormContacto extends Component {

    constructor(props) {
        super(props);

        this.state = {
            nombre: "",
            telefono: "",
            email: "",
            mensaje: "",

            verificado: false,
            enviado: false
        }

        this.handleSubmit = this.handleSubmit.bind(this);
        this.verifyCallback = this.verifyCallback.bind(this);
    }

    handleSubmit(ev) {
        ev.preventDefault();
        let form = document.querySelector(".validate");

        if (form.checkValidity() === false) {
            //Campos invalidos
            ev.preventDefault();
            ev.stopPropagation();
        } else {
            //TODO: Verificar captcha
            //Hacemos submit jeje
            if(this.state.verificado){
                this.setState({...this.state, sending: true});

                console.log(process.env.REACT_APP_API_URL);

                axios.post(`${process.env.REACT_APP_API_URL}contacto`, this.state)
                .then(res => {
                    console.log(res.status);
                    if((res.status === 200) && (res.data.success)){
                        // Mostramos mensaje enviado
                        Swal.fire(
                            'Operación exitosa',
                            'Mensaje enviado',
                            'success'
                        )
                    }else{
                        Swal.fire(
                            'Oops!',
                            'Lo sentimos, algo salió mal',
                            'error'
                        )
                    }
                    this.setState({...this.state, sending: false});
                })
                .catch(err => {
                    console.log(err);
                    Swal.fire(
                        'Oops!',
                        'Lo sentimos, algo salió mal',
                        'error'
                    )
                    this.setState({...this.state, sending: false});
                });
            } else{
                Swal.fire(
                    'Oops...',
                    'Favor de verificar el captcha',
                    'warning'
                )
            }
        }

        form.classList.add('was-validated');
    }

    verifyCallback (response) {
        this.setState({...this.state, verificado: true});
    }

    showButton(){
        if(this.state.sending){
            return <button type="submit" className="btn btn-blanco-amarillo text-uppercase disabled" style={{PointerEvent: "none"}}>Enviando....</button>
        }else{
            return <button type="submit" className="btn btn-blanco-amarillo text-uppercase" onClick={this.handleSubmit}>Enviar</button>
        }
    }

    render () {
        return (
            <form className="validate" noValidate>
                <input type="text" placeholder="Nombre" className="form-control in m15" onChange={(el) => {this.setState({...this.state, nombre: el.target.value})}} required />
                <input type="text" placeholder="Teléfono" className="form-control in m15" onChange={(el) => {this.setState({...this.state, telefono: el.target.value})}} required />
                <input type="email" placeholder="E-mail" className="form-control in m15" onChange={(el) => {this.setState({...this.state, email: el.target.value})}} required />
                <input type="text" placeholder="Mensaje" className="form-control in m15" onChange={(el) => {this.setState({...this.state, mensaje: el.target.value})}} required />

                <div className="text-center d-flex align-items-center justify-content-center m20">
                    <ReCAPTCHA
                        sitekey="6LdrSYIaAAAAABnZA5ttwo4r_XSOh_LJYKoZ8r1k"
                        onChange={this.verifyCallback}
                        size="normal"
                        badge="inline"
                    />
                </div>
                <div className="text-right pt-2 position-relative" style={{zIndex: 1}}>
                    {this.showButton()}
                </div>
            </form>
        );
    }
}

export default FormContacto;
import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import SwiperCore, { Navigation, Autoplay } from 'swiper';
import { Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';

import '../scss/web/home.scss';

import slide1 from '../img/home/slide/slide1.jpg';
import slide2 from '../img/home/slide/slide2.jpg';
import slide3 from '../img/home/slide/slide3.jpg';
import slide4 from '../img/home/slide/slide4.jpg';
import slide1_movil from '../img/home/slide/movil/slide1.jpg';
import slide2_movil from '../img/home/slide/movil/slide2.jpg';
import slide3_movil from '../img/home/slide/movil/slide3.jpg';
import slide4_movil from '../img/home/slide/movil/slide4.jpg';
import logo_nosotros from '../img/home/logo-nosotros.jpg';
import base_servicios from '../img/home/base-servicios.jpg';
import img_servicio_digital from '../img/home/impresion-digital.jpg';
import img_servicio_offset from '../img/home/impresion-offset.jpg';
import img_servicio_formato from '../img/home/impresion-formato.jpg';
import img_tinta_uv from '../img/home/tinta_uv.jpg';

SwiperCore.use([Navigation, Autoplay]);
class Home extends Component {
    render() {
        return (
            <section className="home">
                <div className="bg-circle">
                    {/* Slide */}
                    <div className="home-slide">
                        <div className="container-fluid w14 p-0">
                            <Swiper
                                spaceBetween={0}
                                navigation
                                speed={1500}
                                autoplay={true}
                            >
                                <SwiperSlide>
                                    <div className="bg d-none d-sm-block" style={{backgroundImage: `url(${slide1})`}}> <img src={slide1} alt="Base slide" /> </div>
                                    <div className="bg d-sm-none" style={{backgroundImage: `url(${slide1_movil})`}}> <img src={slide1_movil} alt="Base slide" /> </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="bg d-none d-sm-block" style={{backgroundImage: `url(${slide2})`}}> <img src={slide1} alt="Base slide" /> </div>
                                    <div className="bg d-sm-none" style={{backgroundImage: `url(${slide2_movil})`}}> <img src={slide1_movil} alt="Base slide" /> </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="bg d-none d-sm-block" style={{backgroundImage: `url(${slide3})`}}> <img src={slide1} alt="Base slide" /> </div>
                                    <div className="bg d-sm-none" style={{backgroundImage: `url(${slide3_movil})`}}> <img src={slide1_movil} alt="Base slide" /> </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="bg d-none d-sm-block" style={{backgroundImage: `url(${slide4})`}}> <img src={slide1} alt="Base slide" /> </div>
                                    <div className="bg d-sm-none" style={{backgroundImage: `url(${slide4_movil})`}}> <img src={slide1_movil} alt="Base slide" /> </div>
                                </SwiperSlide>
                            </Swiper>
                        </div>
                    </div>

                    {/* Nuestra empresa */}
                    <div className="home-nosotros">
                        <div className="container-fluid w14">
                            <div className="row align-items-cente">
                                <div className="col-12 col-md-6 col-lg-6 m40 mb-md-0">
                                    <h3 className="titulo-amarillo-negro text-uppercase m40 text-center">Nuestra empresa</h3>
                                    <p className="m30">Le ofrecemos una amplia gama de servicios a través de nuestros sistemas de impresión de digital, offset, y rotativa comercial, los cuales son: tarjetas, volantes, folletos, dípticos, trípticos, etiquetas, empaques, posters, menús, invitaciones, sobres, hojas membretadas, libros, Catálogos, tend cards, material POP entre otros...</p>
                                    <Link to="/nosotros" className="btn btn-blanco-azul">Ver más</Link>
                                </div>
                                <div className="col-12 col-md-6 col-lg-6 text-center">
                                    <img src={logo_nosotros} alt="Graphics Digital" className="text-center w-max-100"></img>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Servicios */}
                <div className="home-servicios" id="servicios">
                    <div className="container-fluid w14 m60 m-lg-80">
                        <div className="row justify-content-center">
                            <div className="col-12 m50 m-lg-100">
                                <h3 className="titulo-negro-blanco text-uppercase text-center">Nuestros servicios</h3>
                            </div>
                            <div className="col-12 col-md-11 col-lg-9">
                                <div className="row">
                                    <div className="col-12 col-md-4 m30 mb-md-0 text-center">
                                        <div className="bg m30 position-relative" style={{backgroundImage: `url(${img_servicio_digital})`}}>
                                            <img src={base_servicios} alt="base servicios"></img>

                                            <div className="pantalla p-3 d-flex align-items-center justify-content-center">
                                                <p>Impresión de tiros cortos, tarjetas de presentación, menús, etiquetas y muchos más…</p>
                                            </div>
                                        </div>
                                        <h4>IMPRESIÓN DIGITAL</h4>
                                    </div>
                                    <div className="col-12 col-md-4 m30 mb-md-0 text-center">
                                        <div className="bg m30 position-relative" style={{backgroundImage: `url(${img_servicio_offset})`}}>
                                            <img src={base_servicios} alt="base servicios"></img>

                                            <div className="pantalla p-3 d-flex align-items-center justify-content-center">
                                                <p>Impresión de volantes, trípticos, folletos, recibos, catálogos y mucho más…</p>
                                            </div>
                                        </div>
                                        <h4>IMPRESIÓN OFFSET</h4>
                                    </div>
                                    <div className="col-12 col-md-4 m30 mb-md-0 text-center">
                                        <div className="bg m30 position-relative" style={{backgroundImage: `url(${img_servicio_formato})`}}>
                                            <img src={base_servicios} alt="base servicios"></img>

                                            <div className="pantalla p-3 d-flex align-items-center justify-content-center">
                                                <p>Impresión en canvas,papel tapiz, tela, vinil, lona, floor graphics y mucho más…</p>
                                            </div>
                                        </div>
                                        <h4>GRAN FORMATO</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="home-servicios-banner">
                    <div className="lines"></div>
                </div>

                <div className="home-uv overflow-hidden">
                    <div className="container-fluid w14">
                        <div className="row">
                            <div className="col-12 col-md-6 col-lg-5 col-xl-4 bg-amarillo">
                                <div className="w-80 mx-auto">
                                    <h4 className="titulo-blanco-negro small-titulo text-uppercase m20">Tecnología de tintas UV</h4>
                                    <p className="mb-0">Impresión en Gran Formato, con calidad Fotográfica en CMYK, más tinta Blanca, barniz brillante y mate.</p>
                                    <p>Imprime tus ideas en CANVAS, PAPEL TAPIZ, TELA, VINIL, LONA, FLOOR GRAPHICS y muchos mas……</p>
                                    <div className="position-relative" style={{zIndex: 1}}>
                                        <Link to="/tecnologia-uv" className="btn btn-amarillo-blanco">Ver más</Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-6 col-lg-7 col-xl-8 bg" style={{backgroundImage: `url(${img_tinta_uv})`}}>

                            </div>
                        </div>
                    </div>
                </div>

            </section>
        );
    }
}

export default Home;
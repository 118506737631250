import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { PageTransition } from '@steveeeie/react-page-transition';

import './fonts/stylesheet.css';
import './scss/app.scss';


import Header from './web/components/Header';
import Footer from './web/components/Footer';

import Home from './web/Home';
import Nosotros from './web/Nosotros';
import Tecnologia from './web/Tecnologia';
import Contacto from './web/Contacto';
import Cotiza from './web/Cotiza';

import reportWebVitals from './reportWebVitals';

const PHome = props => <Home />;
const PNosotros = props => <Nosotros />;
const PTecnologia = props => <Tecnologia />;
const PContacto = props => <Contacto />;
const PCotiza = props => <Cotiza />;


ReactDOM.render(
	<React.StrictMode>
		<BrowserRouter>
			<Header />
			<Route
				render={({ location }) => {
					return (
					  <PageTransition
						preset="fall"
						transitionKey={location.pathname}
					  >
						<Switch location={location}>
							<Route exact path="/" component={PHome} />
							<Route path="/nosotros" component={PNosotros} />
							<Route path="/tecnologia-uv" component={PTecnologia} />
							<Route path="/contacto" component={PContacto} />
							<Route path="/cotizar" component={PCotiza} />
						</Switch>

						<Footer />
					  </PageTransition>
					);
				  }}
			/>
		</BrowserRouter>
	</React.StrictMode>,
	document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

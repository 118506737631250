import React,  { Component } from 'react';
import { Link } from 'react-router-dom';

import logo from '../../img/logo-footer.jpg';
import icon_fb from '../../img/icon-facebook.png';
import icon_in from '../../img/icon-instagram.png';
class Footer extends Component {
    render () {
        return (
            <footer>
                <div className="container-fluid w14">
                    <div className="row align-items-center">
                        <div className="col-12 col-md-4 col-lg-4 text-center text-lg-left m30 mb-md-0 d-lg-flex align-items-lg-center">
                            <img src={logo} className="mr-md-3 logo" alt="Graphics Digital"></img>
                            <span className="text d-block d-lg-inline-block">Graphics. Todos los derechos reservados.</span>
                        </div>
                        <div className="col-12 col-md-4 col-lg-5 text-center m30 mb-md-0">
                            <p className="mb-1">Dirección: Avenida Yucatán #622 Col. Jardines de Mérida</p>
                            <p className="mb-1">Tel: (999) 481 9467</p>
                            <p className="mb-0">Contacto: ventas@graphics.digital</p>
                        </div>
                        <div className="col-12 col-md-4 col-lg-3 text-center text-lg-right m30 mb-md-0">
                            <div className="redes m10">
                                <Link target="_blank" to="https://www.facebook.com/graphicsmid"><img src={icon_fb} className="mr-3" alt="Facebook"></img></Link>
                                <Link target="_blank" to="https://www.instagram.com/graphicsmid/"><img src={icon_in} alt="Instagram"></img></Link>
                            </div>
                            <Link className="text d-block mb-1" to="#">Aviso de Privacidad</Link>
                            <Link className="text d-block" to="#">Términos y Condiciones</Link>
                        </div>
                    </div>
                </div>
            </footer>
        );
    }
}

export default Footer;
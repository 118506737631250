import React, { Component } from 'react';

import FormCotizacion from './components/FormCotizacion';

import '../scss/web/cotiza.scss';
class Cotiza extends Component {
    render() {
        return (
            <section className="cotiza">
                <div className="cotiza-form overflow-hidden">
                    <div className="text-center">
                        <h3 className="titulo-amarillo-negro text-uppercase text-center m40 m-lg-60">COTIZA CON NOSOTROS</h3>

                        <div className="container position-relative">
                            <FormCotizacion />
                        </div>
                    </div>
                </div>

                <div className="cotiza-instagram">
                    <div className="container">
                        <div className="d-flex align-items-center">
                            <h3 className="titulo-amarillo-negro small-titulo text-uppercase mr-3">Instagram</h3>
                            <span className="font-weight-bold">@graphicsmid</span>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default Cotiza;